@import "../../var.scss";
.alert {
  padding: 10px 20px;
  background-color: $darkGrey;
  color: $white;
  border-left: 3px solid transparent;
  border-top-right-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
  margin-bottom: 20px;
  position: relative;
  &__container {
    position: fixed;
    width: 400px;
    right: 20px;
    bottom: 0px;
    z-index: 30;
  }

  &--success {
    border-color: $main;
    .alert__icon {
      color: $main;
    }
  }
  &--error {
    border-color: $red;
    .alert__icon {
      color: $red;
    }
  }
  &--info {
    border-color: $secondary;
    .alert__icon {
      color: $secondary;
    }
  }
  &__time {
    font-style: italic;
    margin-left: 10px;
  }
  &__label {
    margin-left: 10px;
  }
  &__close {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 10px;
    color: $grey;
    &:hover {
      color: $white;
    }
  }
}
