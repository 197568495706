.information
{
    &__headline {
        background-color: rgba(118, 153, 230, 0.733);
        margin-bottom: 10px;
        padding-top: 10px;
        padding-bottom: 3px;
    }

}
#changePassword_button {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 15px;
 }

 #profil_pict {
    height: 100px; 
    width: 100px;
    margin-left: 20px;
    margin-top: 20px;
 }