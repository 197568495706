@import "var.scss";

#breadcrumb {
    margin-top: 15px;
}
.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}