@import "var.scss";

.action {
  $size: 30px;
  border-radius: 50%;
  background-color: #fff;
  color: $main;
  border: 1px solid $main;
  width: $size;
  height: $size;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  margin-right: 5px;
  font-size: 1rem;
  box-sizing: border-box;
  padding: 0px;
  &:focus {
    outline: none;
  }
  &:last-child {
    margin-right: 0px;
  }
  &:before {
    position: absolute;
    //right: -$size - 20px;
    //width: $size;
    left: calc(100% + 5px);
    text-align: center;
    padding: 7px 10px;
    background-color: $main;
    color: #fff;
    border-radius: $borderRadius;
    margin-top: 0px;
    display: none;
    font-family: "Montserrat", Roboto, Arial, sans-serif;
    z-index: 100;
  }
  &:hover {
    background-color: $main;
    color: #fff;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    &:before {
      display: block;
    }
  }
  &--add {
    padding-left: 1px;
    &:before {
      content: "Ajouter";
    }
    &:after {
      content: "\002b";
    }
  }
  &--minus {
    &:before {
      content: "Moins";
    }
    &:after {
      content: "\2212";
    }
  }
  &--edit {
    &:before {
      content: "Editer";
    }
    &:after {
      content: "\270f";
    }
  }
  &--delete {
    padding-right: 1px;
    padding-top: 1px;
    border-color: $juicyRed;
    color: $juicyRed;
    &:hover {
      background-color: $juicyRed;
    }
    &:before {
      background-color: $juicyRed;
      content: "Supprimer";
    }
    &:after {
      content: "\e020";
    }
  }
  &--top {
    padding-bottom: 1px;
    &:before {
      content: "Monter";
    }
    &:after {
      content: "\e093";
    }
  }
  &--bottom {
    padding-right: 1px;
    &:before {
      content: "Descendre";
    }
    &:after {
      content: "\e094";
    }
  }
  &--logout {
    &:before {
      content: "Deconnexion";
    }
    &:after {
      content: "\e163";
    }
  }
  &--download {
    padding-left: 1px;
    &:before {
      content: "Télécharger";
    }
    &:after {
      content: "\e166";
    }
  }
  &--settings {
    &:before {
      content: "Paramètres";
    }
    &:after {
      content: "\e019";
    }
  }
  &--move {
    &:before {
      content: "Déplacer";
    }
    &:after {
      content: "\e068";
    }
  }
  &--simple {
    &:before {
      content: none;
    }
  }
  &--small {
    $size: 24px;
    width: $size;
    height: $size;
    font-size: 0.7rem;
  }
  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
    filter: alpha(opacity=5);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
