@font-face {
  font-family: "The Girl Next Door";
  src: url("../fonts/the_girl_next_door/thegirlnextdoor.ttf") format("ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Regular.eot");
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("../fonts/Montserrat/Montserrat-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-Regular.woff2") format("woff2"), url("../fonts/Montserrat/Montserrat-Regular.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-ExtraBold.eot");
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"), url("../fonts/Montserrat/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-ExtraBold.woff2") format("woff2"), url("../fonts/Montserrat/Montserrat-ExtraBold.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Italic.eot");
  src: local("Montserrat Italic"), local("Montserrat-Italic"), url("../fonts/Montserrat/Montserrat-Italic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-Italic.woff2") format("woff2"), url("../fonts/Montserrat/Montserrat-Italic.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Bold.eot");
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("../fonts/Montserrat/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-Bold.woff2") format("woff2"), url("../fonts/Montserrat/Montserrat-Bold.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-ExtraLight.eot");
  src: local("Montserrat ExtraLight"), local("Montserrat-ExtraLight"), url("../fonts/Montserrat/Montserrat-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-ExtraLight.woff2") format("woff2"), url("../fonts/Montserrat/Montserrat-ExtraLight.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
