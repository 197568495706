/* Colors */
$lightMain: rgb(250, 250, 250);
$juicyMain: #5c7ee9;
$main: #348cf0;
$darkMain: #6962ef;
$secondary: #3199dd;
$lightGrey: rgb(245, 245, 245);
$grey: rgb(200, 200, 200);
$darkGrey: rgb(90, 90, 90);
$background: #f5f1f4;
$black: #212121;
$red: rgb(145, 12, 12);
$juicyRed: #fa3333;
$orange: #ff7f11;
$white: #fff;

/* Size */
$borderRadius: 14px;

// /* fonts */
// $mainFont : ;
// $secondaryFont : ;
