@import "styles/reset.css";
@import "styles/glyphicon.scss";
@import "styles/font.scss";
@import "var.scss";
@import "styles/table.scss";
@import "styles/form.scss";
@import "styles/action.scss";

body {
  font-family: "Montserrat", Roboto, Arial, sans-serif;
  color: $black;
}

.container {
  width: 1200px;
  max-width: 90%;
  margin: auto;
  position: relative;
  z-index: 1;
}

.App {
  padding: 20px 0px;
}

.page {
  background-color: $background;
  min-height: 600px;
  padding: 30px 0px;
}

h2.subtitle {
  color: $secondary;
  font-size: 1.4rem;
  margin-top: 10px;
  margin-bottom: 30px;
  font-weight: bolder;
  display: flex;
  align-items: flex-end;
  &:after {
    content: "";
    width: 300px;
    height: 1px;
    background-color: $grey;
    margin-left: 20px;
  }
}

h3.subtitle {
  font-weight: bolder;
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: $darkGrey;
}

p.subtitle {
  font-weight: bolder;
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 5px;
}

p {
  line-height: 1.3rem;
  margin-bottom: 10px;
}

.link {
  color: $secondary;
  background-color: none;
  text-decoration: underline;
  border: none;
  padding: 0px;
  margin-bottom: 10px;
  font-size: 1rem;
  cursor: pointer;
}

.list__filter {
  display: flex;
  position: sticky;
  top: 111px;
  align-items: center;
  justify-content: space-between;
  z-index: 8;
  background-color: $juicyMain;
  color: $white;
  padding: 0px 20px;
  border-bottom-left-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
  margin-top: -30px;
  &-header {
    font-weight: bolder;
    margin-right: 10px;
  }
  .input__field {
    color: $black;
    // background: transparent;
    // color: $white;
  }
}
.list-filter {
  display: flex;
  align-items: center;
}

.overlay__background {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9;
}

.forbidden {
  pointer-events: none;
  filter: grayscale(100%);
  & > * {
    filter: grayscale(100%);
  }
}

.shadow {
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.5);
}

.shadow--dark {
  box-shadow: 9px 9px 16px rgba(20, 20, 20, 0.9), -9px -9px 16px rgba(20, 20, 20, 0.9);
}

.shadow--scroll {
  background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100% !important;
  background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100% !important;
  background-repeat: no-repeat !important;
  background-color: white !important;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px !important;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll !important;
}