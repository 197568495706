// /* TABLE */

table {
  width: 100%;
  margin-bottom: 20px;
}

thead {
  background-color: $main;
}

th {
  padding: 20px 10px;
  border: 1px solid white;
  color: white;
  position: relative;
  &:first-child {
    border-left: 0px;
  }
  &:last-child {
    border-right: 0px;
  }
}

.table {
  &__th {
    &--sortable {
      &:hover {
        cursor: pointer;
        background-color: $darkMain;
      }
    }
  }
  &__td {
    &--actions {
      display: flex;
      justify-content: space-around;
    }
  }
}

tbody {
  text-align: left;
  tr {
    box-sizing: border-box;
    &:nth-child(2n + 1) {
      background-color: white;
    }
    &.tr--selected {
      border: 1px solid $juicyMain;
      background-color: #5c7ee922;
      border-collapse: collapse;
    }
    td {
      padding: 10px 20px;
      border-right: 1px solid white;
      vertical-align: middle;
      &:last-child {
        border-right: 0px;
      }
      &.td--checkbox {
        cursor: pointer;
        position: relative;
        [type="checkbox"] {
          position: absolute;
          left: 0;
          opacity: 0.01;
        }
        [type="checkbox"]:checked + label {
          &:after {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-color: red;
          }
        }
      }
    }
  }
}
