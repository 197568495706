
@import "var.scss";

.billing
{
    &__headline {
        background-color: rgba(208, 219, 188, 0.733);
        margin-bottom: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
    }

}
thead {
    background-color: #a9bacee3;
    th {
        font-weight: bold;
    }
    .smallColumn {
        width: 150px;
    }

}    
.smallButton {
    padding: 0.3rem 0.3rem;
    font-size: 16px;
  }