@import "var.scss";

.password {
  &__strength {
    //margin-left: 25%;
    height: 5px;
    display: block;
    background-color: #5c7ee9;
    margin-top: -30px;
    margin-bottom: 20px;
  }
  &__policies {
    margin-bottom: 20px;
    //margin-left: 25%;
  }
  &__policy {
    padding-left: 20px;
    &--nok {
      color: $red;
      list-style: none;
      &:before {
        content: "×";
        margin-right: 10px;
      }
    }
    &--ok {
      color: $juicyMain;
      list-style: none;
      &:before {
        content: "✓";
        margin-right: 10px;
      }
    }
  }
}
