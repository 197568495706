@import "var.scss";

.login {
  &__header {
    position: absolute;
    top: -50px;
    left: -5%;
    width: 110%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: $main;
    color: $white;
    border-radius: $borderRadius;
    padding: 20px 10%;
    box-sizing: border-box;
    font-size: 2rem;
    font-family: "The Girl Next Door", cursive;
  }
  &__logo {
    width: 280px;
    margin: auto;
    display: block;
  }
  &-page {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../assets/belleIle.jpg");
    background-size: cover;
    min-height: 1500px;
    padding-top: 100px;
    .container {
      padding: 20px 20px 20px 20px;
      position: relative;
      background-color: $background;
      border-radius: $borderRadius;
      width: 500px;
      max-width: 90%;
      margin: auto;
      //border: 2px solid $grey;
    }
  }
}
