@import "var.scss";

.AccordionTitle {
    display: flex;
    min-height: 48px;
}

.AccordionTitleLine {
    margin: 5px;
    padding: 10px;
    display: flex;
    flex-grow: 1;
    transition: margin 150ms
}

.smallHeader-card {
    padding-top: 0;
    padding-bottom: 0;
}

.jumbotron {
    padding: 10px;
    background-color: #cee1f5;
}

.IconButton-label {
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: inherit;
}

.SvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.AccordionTitleLineIcon {
    padding: 12px;
    padding-right: 5px;
}

.popupTitle-container {
    display: flex;
} 

.popupTitle-container a {
    padding: 18px;
    text-decoration: none;
    color: black;
}

.popupTitle {
    display: flex;
    flex-grow: 1;
    padding: 12px;
}