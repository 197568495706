.btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 0px;
  .btn {
    margin: 0px 10px;
    &:last-child {
      margin-right: 0px;
    }
    &:first-child {
      margin-left: 0px;
    }
  }
  &--nav {
    justify-content: space-between;
  }
}

.btn {
  border-radius: $borderRadius;
  border: 0px;
  font-size: 1.2rem;
  padding: 10px 20px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
  &:hover {
    transition: all 0.2s ease-in-out;
  }
  &--next:after,
  &--prev:before {
    font-size: 0.8rem;
    font-family: "Glyphicons Halflings";
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  &--prev:before {
    content: "\e091";
    margin-right: 10px;
  }
  &--next:after {
    content: "\e092";
    margin-left: 10px;
  }
  &--primary {
    background: $main;
    border: 1px solid $darkMain;
    color: $white;
    &:hover {
      background: $white;
      color: $main;
      border-bottom-width: 2px;
      border-right-width: 2px;
      //box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.5);
    }
  }
  &--secondary {
    background-color: white;
    border: 1px solid $main;
    color: $main;
    border-bottom-width: 2px;
    border-right-width: 2px;
    &:hover {
      background: $main;
      border-color: $darkMain;
      border-color: transparent;
      color: white;
      //box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.5);
    }
  }
  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
    filter: alpha(opacity=5);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &--small {
    font-size: 0.8rem;
    padding: 5px 10px;
  }
  &--center {
    margin: auto;
    margin-top: 20px;
  }
  &--add {
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 50%;
    margin: 50px auto;
    &:before {
      content: "\002b";
      font-family: "Glyphicons Halflings";
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-right: 20px;
    }
  }
  &:active {
    box-shadow: 9px 9px 16px $main, -9px -9px 16px $secondary;
  }
}

textarea,
input,
select,
button {
  &:focus,
  &:active {
    outline: none;
  }
}

.input {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  width: 100%;
  //margin: auto;
  margin-bottom: 30px;
  &--l {
    width: 100%;
  }

  &--m {
    width: 50%;
  }

  &--s {
    width: 30%;
  }
  &__label {
    margin-bottom: 5px;
    &-required {
      color: $red;
      margin-left: 5px;
    }
  }
  &-input--inline {
    display: inline-flex;
    margin: 0px 5px;
  }
  $height: 44px;
  $paddingVertical: 12px;
  &__field {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    font-size: 1rem;
    padding: $paddingVertical 10px;
    box-sizing: border-box;
    border: none;
    border-bottom: 2px solid $grey;
    border-radius: 0px;
    //background: $white;
    color: inherit;
    position: relative;
    height: $height;
    //box-sizing: content-box;
    &--selectable {
      background: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      display: flex;
      align-items: center;
      label {
        margin-left: 5px;
      }
    }
    &-container {
      position: relative;
      width: 100%;
      &:after {
        content: "";
        position: absolute;
        width: 0%;
        height: 2px;
        top: $height - 2px;
        left: 50%;
      }
      &:focus-within:after,
      &:hover:after {
        width: 100%;
        left: 0%;
        transition: all 0.2s ease-in-out;
      }
      &:hover:after {
        background-color: $secondary;
      }
      &:focus-within:after {
        background-color: $main;
      }
      &--arrow {
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          margin-top: -3px;
          right: 0.75em;
          display: block;
          width: 0;
          height: 0;
          border-color: transparent;
          border-top-color: #444;
          border-width: 6px;
          border-style: solid;
          pointer-events: none;
        }
      }
    }
  }
  &__error {
    color: $red;
    font-size: 0.8rem;
  }
  &--error {
    .input__field {
      border-color: $red;
    }
  }
  &--inline {
    display: inline-flex;
    vertical-align: top;
    margin: 0px 10px 10px 0px;
    width: auto;
    label {
      height: 16px;
    }
    &:not(:last-child) {
      margin-right: 10px;
    }
    .input {
      &__label {
        margin-bottom: 5px;
      }
      &__field {
        width: 100%;
        .input__label,
        label {
          height: 42px;
        }
      }
    }
  }
}

.form {
  &--conditional {
    padding: 30px 100px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: $borderRadius;
    margin-bottom: 25px;
  }
}
